<template>
    <div class="time-period">
        <a-button type="primary" icon="plus" @click="showModal">添加时间段</a-button>
        <drag-zone :list="list" item-class="time-period-item" class="time-period-list">
            <template v-slot="{item}">
                <div class="time-period-text">
                    <span>{{item.start}} - {{item.end}}</span>
                </div>
                <a-icon type="delete" class="time-period-delete" @click="deletePeriod(i)" />
            </template>
        </drag-zone>
        <a-modal v-model="visible" title="添加时间段" ok-text="确认" cancel-text="取消" @ok="confirm">
            <div class="flex-box align-center">
                <a-time-picker v-model="start" format="HH:mm" value-format="HH:mm" placeholder="开始时间" @change="checkTip" />
                <div class="time-period-input-gap">至</div>
                <a-time-picker v-model="end" format="HH:mm" value-format="HH:mm" placeholder="结束时间" @change="checkTip" />
            </div>
            <div class="tip" :class="{show: tip}">{{tip}}</div>
        </a-modal>
    </div>
</template>

<script>
    import dragZone from './drag-zone'

    export default {
        name: "time-period",
        model: {
            event: 'change'
        },
        components: {
            dragZone
        },
        props: {
            value: Array
        },
        data() {
            return {
                list: [],
                visible: false,
                start: null,
                end: null,
                tip: null
            }
        },
        watch: {
            value() {
                this.setValue();
            },
        },
        created() {
            this.setValue();
        },
        methods: {
            setValue() {
                let value = this.value;
                if(value) {
                    let now = Date.now();
                    this.list = value.map((item, i) => {
                        return {
                            start: item.start,
                            end: item.end,
                            key: now + i
                        }
                    })
                }
            },
            deletePeriod(i) {
                this.list.splice(i, 1);
                this.dispatchInput();
            },
            showModal() {
                this.tip = null;
                this.start = null;
                this.end = null;
                this.visible = true;
            },
            hideModal() {
                this.visible = false;
            },
            checkTip() {
                let start = this.start, end = this.end;
                if(!start) {
                    this.tip = '请选择开始时间'
                } else if(!end) {
                    this.tip = '请选择结束时间'
                } else {
                    this.tip = null;
                }
            },
            confirm() {
                this.checkTip();
                if(this.tip) return;
                this.list.push({
                    start: this.start,
                    end: this.end,
                    key: Date.now()
                });
                this.dispatchInput();
                this.hideModal();
            },
            dispatchInput() {
                let list = this.list;
                if(list && list.length > 0) {
                    this.$emit("change", this.list.map(item => {
                        return {
                            start: item.start,
                            end: item.end
                        }
                    }))
                }
            }
        }
    }
</script>

<style lang="less">
    .time-period-input-gap {
        margin: 0 10px;
    }
    .tip {
        margin-top: 6px;
        height: 21px;
    }
    .time-period-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .time-period-text {
        padding: @padding-xs;
        border: var(--border);
        border-radius: @border-radius-base;
        line-height: 1.2;
    }
    .time-period-delete {
        margin-left: 12px;
        cursor: pointer;
    }
</style>
