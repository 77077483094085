<template>
    <div class="tab-bar">
       <div class="tab-item" :class="{active: tab === i}" v-for="(t, i) in list" @click="selectTab(i)" :key="i">
           <slot name="tab" v-bind:tab="t" v-if="$scopedSlots.tab"></slot>
           <template v-else>
               <span>{{t}}</span>
           </template>
           <div class="tab-active"></div>
       </div>
    </div>
</template>

<script>
    export default {
        name: "tab-bar",
        props: {
            list: Array,
            tab: Number //初始选择的tab
        },
        methods: {
            selectTab(i) {
                this.$emit("update:tab", i);
            }
        }
    }
</script>

<style lang="less">
    .tab-bar {
        padding: @padding-md;
        width: 152px;
    }
    .tab-item {
        position: relative;
        padding: @padding-xs @padding-sm;
        color: @text-color-secondary;
        cursor: pointer;
        &.active {
            color: @heading-color;
            .tab-active {
                display: block;
            }
        }
    }
    .tab-active {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        height: 4px;
        border: 4px solid transparent;
        border-left: 6px solid @heading-color;
    }
</style>
