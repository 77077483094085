<template>
    <div style="border: 1px solid #ccc;">
        <!-- 工具栏 -->
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editorId="id"
            :defaultConfig="toolbarConfig"/>
        <!-- 编辑器 -->
        <Editor
            style="height: 500px"
            :editorId="id"
            :defaultConfig="editorConfig"
            :defaultContent="getDefaultContent"
            @onCreated="onCreated"
            @onChange="onChange"/>
    </div>
</template>

<script>
    import { Editor, Toolbar, getEditor, removeEditor } from '@wangeditor/editor-for-vue';
    import { SlateTransforms } from '@wangeditor/editor'
    import {clone, isArray} from "../common/js/tool";

    export default {
        name: "wang-editor",
        components: { Editor, Toolbar },
        props: {
            value: [String, Array],
            id: {
                type: String,
                default: 'wang_editor'
            }
        },
        data() {
            return {
                defaultContent: [], // 编辑器的默认内容，只在初始化时使用
                latestContent: [], // 用于存储编辑器最新的内容，onChange 时修改
                toolbarConfig: {},
                editorConfig: {
                    placeholder: '请输入内容...',
                }
            }
        },
        computed: {
            getDefaultContent() {
                return clone(this.defaultContent) // 深拷贝，重要！！！
            }
        },
        methods: {
            setValue() {
                let val = this.value, editor = this.editor;
                if(val && isArray(val)) {
                    editor.select([])
                    editor.deleteFragment();
                    SlateTransforms.insertNodes(editor, val);
                }
            },
            onCreated(editor) {
                this.editor = editor;
                this.setValue();
            },
            onChange(editor) {
                this.latestContent = editor.children;
                this.$emit("input", editor.children);
            },
        },
        beforeDestroy() {
            const editor = getEditor(this.id)
            if (editor == null) return
            editor.destroy() // 组件销毁时，及时销毁编辑器 ，重要！！！
            removeEditor(this.id)
        },
    }
</script>

<style scoped>

</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
