import E from 'wangeditor';
const { BtnMenu } = E;
class formatButton extends BtnMenu {
    constructor(editor) {
        // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
        const $elem = E.$(
            `<div class="w-e-menu" data-title="转换格式">
                <div>转换</div>
            </div>`
        )
        super($elem, editor);
    }
    // 菜单点击事件
    clickHandler() {
        // 做任何你想做的事情
        let editor = this.editor;
        let html = editor.txt.html();
        html = html.replace(/<font size="1"/g, '<span style="font-size: 10px;"')
            .replace(/<font size="2"/g, '<span style="font-size: 13px;"')
            .replace(/<font size="3"/g, '<span style="font-size: 16px;"')
            .replace(/<font size="4"/g, '<span style="font-size: 18px;"')
            .replace(/<font size="5"/g, '<span style="font-size: 24px;"')
            .replace(/<font size="6"/g, '<span style="font-size: 32px;"')
            .replace(/<font size="7"/g, '<span style="font-size: 48px;"')
            .replace(/\/font>/g, '/span>')
            .replace(/<section/g, '<p')
            .replace(/\/section>/g, '/p>');
        editor.txt.html(html);
    }
    // 菜单是否被激活（如果不需要，这个函数可以空着）
    tryChangeActive() {
    }
}

export default formatButton;
