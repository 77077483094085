import Vue from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import {Spin} from "ant-design-vue";
Spin.setDefaultIndicator({
    indicator: {
        render() {
            return <a-icon type="loading" style="font-size: 24px" spin />;
        }
    }
});

Vue.use(Antd)
