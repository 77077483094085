const state = {
    update: null
};

// getters
const getters = {
    update: state => state.update,
};

// mutations
const mutations = {
    changeUpdate(state, value) {
        state.update = value;
    },
};

const actions = {
};

export default {
    state,
    getters,
    mutations,
    actions
}
