<template>
    <div class="editor-box">
        <div class="rich-editor"></div>
        <div class="upload-process" v-show="bs">
            <a-progress type="circle" :percent="p">
                <template #format="percent">
                    <div class="process-p">{{ percent }}%</div>
                    <div class="process-txt">正在上传</div>
                </template>
            </a-progress>
        </div>
    </div>
</template>

<script>
    import E from 'wangeditor';
    import formatButton from "./button";
    //import {uploadFile} from "../common/upload";
    import {directUploadTencentCloud} from "../../common/js/tencent_cloud";

    export default {
        name: "rich-editor",
        props: {
            height: Number,
            value: String
        },
        data() {
            return {
                editor: null,
                bs: false,
                p: 0
            }
        },
        watch: {
            value() {
                if(this.editor) {
                    this.setValue();
                }
            }
        },
        mounted() {
            this.init();
        },
        beforeDestroy() {
            this.editor && this.editor.destroy();
            this.editor = null;
        },
        methods: {
            setValue() {
                let val = this.value;
                if(val) {
                    this.editor.txt.html(val);
                } else {
                    this.editor.txt.clear();
                }
            },
            init() {
                const editor = new E(this.$el.querySelector(".rich-editor"));
                let menuKey = 'format'
                editor.menus.extend(menuKey, formatButton);
                editor.config.menus = editor.config.menus.concat(menuKey);
                editor.config.height = this.height || 500;
                editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
                editor.config.uploadImgMaxLength = 1;
                editor.config.customUploadImg = function (resultFiles, insertImgFn) {
                    let file = resultFiles[0];
                    directUploadTencentCloud(file,progress=>{
                        this.p = Math.round(progress.percent * 100);
                    }).then(data=>{
                        let path = "https://" + data.Location;
                        insertImgFn(path);
                    })
                };
                editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
                    this.bs = true;
                    let file = resultFiles[0];
                    directUploadTencentCloud(file, progress => {
                        this.p = Math.round(progress.percent * 100);
                    }).then(data => {
                        let path = "https://" + data.Location;
                        insertVideoFn(path);
                        this.bs = false;
                    }).catch(() => {
                        this.bs = false;
                    })
                }
                editor.config.onchange = html => {
                    this.$emit("input", html);
                }
                editor.config.lineHeights = ['1', '1.15', '1.3', '1.6', '2', '2.5', '3']
                editor.create();
                this.editor = editor;
                this.setValue();
            }
        }
    }
</script>

<style scoped lang="less">
    .editor-box {
        position: relative;
        z-index: 1;
    }
    .upload-process {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        z-index: 10002;
    }
    .process-p {
        color: @primary-color;
    }
    .process-txt {
        margin-top: 10px;
    }
</style>
