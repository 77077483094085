import axios from "./req";
import config from "./config";
import { message} from 'ant-design-vue';

const uploadImg = function (arg) {
  let file = arg.file;
  let formData = new FormData();
  formData.append('file', file);
  let url = arg.local ? '/live/upload' : '/live/upload-to-qcloud';
  return axios({
    url,
    method: "POST",
    data: formData
  }).then(res => {
    if(res.error == 0) {
      let path;
      if(arg.local) {
        path = config.host + res.path;
      } else {
        path = "https://" + res.path;
      }
      return path;
    } else {
      message.error(res.msg);
      throw new Error('upload error!');
    }
  });
}

/**
 *
 * @param file 文件对象
 * @param size 限制上传的文件大小 单位 kb
 */
const beforeUpload = function (file, size) {
  size = size || 500;
  let fz = file.size;
  if(fz > size * 1024) {
    let text = size + "KB";
    let m = size / 1024;
    if(m >= 1) {
      text = m + 'MB';
    }
    message.warning(`图片大小不能超过${text}`)
    return false;
  } else {
    return  true;
  }
}

export {
  uploadImg,
  beforeUpload
}
