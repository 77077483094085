import { render, staticRenderFns } from "./wang-editor.vue?vue&type=template&id=77212fbb&scoped=true&"
import script from "./wang-editor.vue?vue&type=script&lang=js&"
export * from "./wang-editor.vue?vue&type=script&lang=js&"
import style1 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77212fbb",
  null
  
)

export default component.exports