<template>
    <div class="form-box">
        <a-form-model
                class="form-area"
                ref="ruleForm"
                :label-align="labelAlign"
                :model="form"
                :rules="rules"
                :layout="layout"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
            <a-form-model-item :label="item.label" :prop="item.key" v-for="item in controls" :key="item.key">
                <component
                    :is="item.component"
                    :style="item.style"
                    v-model="form[item.key]"
                    v-bind="item.props"
                    v-on="encapsulationListeners(item.listeners)">
                    <template v-if="item.component == 'a-radio-group'">
                        <a-radio :value="typeof opt === 'object' ? opt.value : opt" v-for="(opt, j) in item.options" :key="j">{{typeof opt === 'object' ? opt.text : opt}}</a-radio>
                    </template>
                    <template v-else-if="item.component == 'a-checkbox-group'">
                        <a-checkbox :value="typeof opt === 'object' ? opt.value : opt" v-for="(opt, j) in item.options" :key="j">{{typeof opt === 'object' ? opt.text : opt}}</a-checkbox>
                    </template>
                </component>
            </a-form-model-item>
        </a-form-model>
        <a-space class="footer">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleConfirm">{{confirmText}}</a-button>
        </a-space>
    </div>
</template>

<script>
    import {clone} from "../common/js/tool";
    const entityConfig = {
        group: {
            url: '/group',
            dealOptions: function(list) {
                let options = list.map(item => {
                    return {
                        key: item.id,
                        title: item.name
                    }
                });
                options.push({
                    key: 0,
                    title: '其他'
                });
                return options;
            }
        }
    }

    export default {
        name: "form-area",
        props: {
            form: {
                type: Object,
                default() {
                    return {};
                }
            },
            items: Array,
            labelCol: Object,
            wrapperCol: Object,
            labelAlign: {
                type: String,
                default: 'left'
            },
            layout: {
                type: String,
                default: 'vertical'
            },
            confirmText: {
                type: String,
                default: '保存'
            }
        },
        data() {
            return {
                controls: []
            }
        },
        computed: {
            rules() {
                let items = this.controls;
                let rules = {};
                if(items) {
                    items.forEach(item => {
                        if(item.rules) {
                            rules[item.key] = item.rules;
                        }
                    });
                }
                return rules;
            }
        },
        watch: {
            items() {
                this.setControls();
            }
        },
        created() {
            this.setControls();
        },
        methods: {
            setControls() {
                let controls =  clone(this.items);
                controls.forEach(item => {
                    if(item.entity) {
                        let conf = entityConfig[item.entity];
                        if(conf) {
                            this.$axios(conf.url).then(res => {
                                let list = res.data;
                                typeof conf.dealOptions === 'function' && (list = conf.dealOptions(list));
                                this.$set(item.props, 'options', list);
                            })
                        }
                    }
                })
                this.controls = controls;
            },
            encapsulationListeners(listeners) {
                let res = {};
                for(let key in listeners) {
                    let handler = listeners[key];
                    res[key] = $event => {
                        handler.call(this, $event);
                    }
                }
                return res;
            },
            handleConfirm() {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.$emit("confirm", this.form);
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handleCancel() {
                this.$emit("cancel");
            }
        }
    }
</script>

<style scoped lang="less">
    .form-box {
        display: flex;
        flex-direction: column;
    }
    .form-area {
        flex: 1;
        padding: 24px;
        overflow: auto;
    }
    .footer {
        margin-top: 16px;
        padding: 16px 0;
        border-top: var(--border);
        justify-content: center;
    }
</style>
