import { Modal } from 'ant-design-vue';
import { IDLE_TIME } from "./config";

let timer = 0, modal = null, second = 5, secondTimer = 0;

const ignorePath = [
    "/login",
    "/screen",
    "/password"
]

const clearSecondTimer = () => {
    if (secondTimer) {
        clearInterval(secondTimer);
        secondTimer = 0;
    }
}

const goLogin = () => {
    location.replace('/login');
}

const showModal = () => {
    modal = Modal.confirm({
        title: "温馨提示",
        content: `由于您长时间未操作,系统将在${second}s退出登陆`,
        onCancel: () => {
            clearSecondTimer();
            resetHome();
        },
        onOk: () => {
            clearSecondTimer();
            goLogin();
        }
    });
    secondTimer = setInterval(() => {
        if(second > 0) {
            modal.update({
                content: `由于您长时间未操作,系统将在${second}s退出登陆`
            })
            second -= 1;
        } else {
            goLogin();
        }
    }, 1000);
}

export const resetHome = () => {
    clearDetectIdleTimer();
    // 首页不监听
    if (!ignorePath.includes(location.pathname)) {
        timer = setTimeout(showModal, IDLE_TIME * 1000);
    }
};

export const clearDetectIdleTimer = () => {
    if (timer) {
        clearTimeout(timer);
        timer = 0;
    }
};

export const addDetectIdleListener = () => {
    document.addEventListener("click", resetHome);
    document.addEventListener("touchstart", resetHome);
};

export const removeDetectIdleListener = () => {
    document.removeEventListener("click", resetHome);
    document.removeEventListener("touchstart", resetHome);
};

export const detectIdle = function () {
    addDetectIdleListener();
};
