import dataList from './data-list';
import formArea from './form-area';
import formUpload from './form-upload';
import richEditor from './rich-editor/index';
import wangEditor from './wang-editor'
import tabBar from './tab-bar';
import selectEntity from './select-entity';
import coordinatePicker from './coordinate-picker';
import timePeriod from './time-period';
import liveConfig from './live-config'

const install = function (Vue) {
    Vue.component("dataList", dataList);
    Vue.component("formUpload", formUpload);
    Vue.component("richEditor", richEditor);
    Vue.component("wangEditor", wangEditor);
    Vue.component("selectEntity", selectEntity);
    Vue.component("coordinatePicker", coordinatePicker);
    Vue.component("timePeriod", timePeriod);
    Vue.component("liveConfig", liveConfig);
    Vue.component("formArea", formArea);
    Vue.component("tabBar", tabBar);
    Vue.component("Vnodes", {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes
    });
}

export default install;
