<template>
  <router-view/>
</template>

<script>
  import {detectIdle} from "./common/js/sys";

  export default {
    name: 'app',
    created() {
        detectIdle();
    }
  }
</script>

<style lang="less">
  @import "./common/less/common";
</style>
