import axios from "./req";
import {getSuffix, randomString} from "./tool";
import { message} from 'ant-design-vue';
const COS = require('cos-js-sdk-v5');
const conf = {
    Bucket: 'museum-1301841918',
    Region: 'ap-nanjing'
}

let cos = new COS({
    // 必选参数
    getAuthorization: function (options, callback) {
        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
        axios({
            url: '/live/sts',
            method: 'GET',
            loadingText: false
        }).then(res =>{
            let data = res && res.data;
            typeof data === "string" && (data = JSON.parse(data));
            if(data && data.credentials) {
                let credentials = data.credentials;
                callback({
                    TmpSecretId: credentials.tmpSecretId,
                    TmpSecretKey: credentials.tmpSecretKey,
                    XCosSecurityToken: credentials.sessionToken,
                    // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                    StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                    ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                });
            } else {
                console.error('credentials invalid');
                callback({});
            }
        }).catch(() => {
            callback({});
        });
    }
});


const directUploadTencentCloud = function (file, onProgress) {
    return new Promise((resolve, reject) => {
        let fileName = new Date().getTime()+randomString() + getSuffix(file.name);
        cos.putObject({
            Bucket: conf.Bucket, /* 必须 */
            Region: conf.Region,     /* 存储桶所在地域，必须字段 */
            Key: fileName,              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: progress => {
                typeof onProgress === "function" && onProgress(progress);
            }
        }, function(err, data) {
            if(err) {
                message.error("上传文件出错");
                reject(err);
            } else {
                data.fileName = fileName;
                resolve(data);
            }
        });
    });
}

const deleteObject = function (fileName, Bucket, Region) {
    return new Promise((resolve, reject) => {
        cos.deleteObject({
            Bucket: Bucket || conf.Bucket, /* 必须 */
            Region: Region || conf.Region,     /* 存储桶所在地域，必须字段 */
            Key: fileName,              /* 必须 */
        }, function(err, data) {
            if(err) {
                message.error("删除文件出错");
                reject(err);
            } else {
                resolve(data);
            }
        });
    })
}


export {directUploadTencentCloud, deleteObject}
