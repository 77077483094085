const dev = false;
const localHost = 'http://united.qihe.local';
const proHost = 'https://united.zj-qq.cn';
const devHost = dev ? localHost : proHost;
const host = process.env.NODE_ENV === 'production' ? proHost : devHost;

export const IDLE_TIME = 15 * 60; // 多长时间未操作退出登陆 单位 s

export default {
  host,
  token: null
}
