import Vue from 'vue'
import VueRouter from 'vue-router'
import {getStorageInfo, getToken} from "../common/js/storage";
import store from "../store/index";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {clearDetectIdleTimer, resetHome} from "../common/js/sys";
// import RouteView from "../layouts/RouteView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "view" */ '../views/Home.vue'),
    name: 'Home',
    redirect: {name: 'Approve'},
    children: [
      {
        path: 'approve',
        name: 'Approve',
        component: () => import(/* webpackChunkName: "l" */ '../views/Approve.vue'),
        meta: {
          keepAlive: true,
          title: '我的审批'
        },
      },
      {
        path: 'org',
        name: 'Organization',
        component: () => import(/* webpackChunkName: "l" */ '../views/Organization.vue'),
        meta: {
          title: '人员设置'
        },
      },
      {
        path: 'log',
        name: 'Log',
        component: () => import(/* webpackChunkName: "log" */ '../views/Log.vue'),
        meta: {
          title: '系统日志'
        },
      },
      {
        path: '/process',
        component: () => import(/* webpackChunkName: "process" */ '../views/process/Process.vue'),
        children: [
          {
            path: '',
            name: 'ProcessList',
            component: () => import(/* webpackChunkName: "pl" */ '../views/process/ProcessList.vue'),
            meta: {
              keepAlive: true
            },
          },
          {
            path: 'add',
            name: 'ProcessAdd',
            component: () => import(/* webpackChunkName: "pa" */ '../views/process/ProcessDetail.vue'),
            meta: {
              keepAlive: true
            },
          },
          {
            path: 'detail/:id',
            name: 'ProcessDetail',
            component: () => import(/* webpackChunkName: "pd" */ '../views/process/ProcessDetail.vue'),
            meta: {
              keepAlive: true
            },
          },
        ]
      },
      {
        path: '/entity/:type',
        component: () => import(/* webpackChunkName: "l" */ '../views/Entity.vue'),
        children: [
          {
            path: '',
            name: 'List',
            component: () => import(/* webpackChunkName: "l" */ '../views/EntityList.vue'),
            meta: {
              keepAlive: true
            },
          },
          {
            path: 'add',
            name: 'AddEntity',
            component: () => import(/* webpackChunkName: "a" */ '../views/EntityDetail.vue'),
            meta: {
              keepAlive: false
            },
          },
          {
            path: 'detail/:id',
            name: 'EntityDetail',
            component: () => import(/* webpackChunkName: "d" */ '../views/EntityDetail.vue'),
            meta: {
              keepAlive: false
            },
          }
        ]
      },
    ]
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import(/* webpackChunkName: "password" */ '../views/Password.vue'),
    meta: {
      open: true,
      title: '修改密码'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      open: true,
      title: '登录'
    }
  },
  {
    path: '/screen',
    name: 'Screen',
    component: () => import(/* webpackChunkName: "screen" */ '../views/Screen.vue'),
    meta: {
      open: true,
      title: '“杭统云E家”可视化数据平台'
    }
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "404" */ '../views/Forbidden.vue'),
    meta: {
      open: true
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue'),
    meta: {
      open: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  let token = getToken();
  token && store.commit("setLogin", true);
  if(!token && !to.meta.open) {
    next({name: 'Login', query: {r: to.path}});
  } else {
    let user = getStorageInfo();
    if(!to.meta.open && user.role == 2 && to.name !== 'Approve') {
        next({name: 'Forbidden'})
    } else {
      let meta = to.meta;
      //页面标题
      let title = meta.title;
      if (title) {
        document.title = title;
      }
      next();
    }
  }
});

router.afterEach((to) => {
  NProgress.done();
  // 路由变化时
  if (to.name == "Login") {
    clearDetectIdleTimer();
  } else {
    resetHome();
  }
});

export default router
